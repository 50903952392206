'use client';

import { Drawer } from 'vaul';
import React from 'react';
import { cn } from '@/lib/utils';

interface VaulDrawerProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode[];
  noOverlay?: boolean;
  triggerClassName?: string; // New prop for trigger class names
}

export default function VaulDrawer({ children, className, noOverlay, triggerClassName, ...props }: VaulDrawerProps) {
  return (
    <Drawer.Root handleOnly direction='right'>
      <Drawer.Trigger className={cn('w-full', triggerClassName)}>{children[0]}</Drawer.Trigger>
      <Drawer.Portal>
        {!noOverlay && (
          <Drawer.Overlay className='fixed inset-0 z-10 bg-black/10' />
        )}
        <Drawer.Content
          className={cn(
            'right-2 top-2 bottom-2 fixed z-20 w-2/5 flex !select-text !cursor-text',
            className
          )}
          {...props}
        >
          {/* Rotate and position the handle to be vertical, middle left */}
          <Drawer.Handle className='absolute left-7 top-1/2 -translate-y-1/2 rotate-90 cursor-grab' />
          <div className='bg-zinc-50 h-full w-full grow p-5 pl-7 flex flex-col rounded-[16px] border-1 border-gray-500'>
            {children[1]}
          </div>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer.Root>
  );
}

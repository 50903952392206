import { DDQPairWithMeta } from '@/types';
import { SimilarPair } from '@/types';
import { useAuthInfo } from '@propelauth/react';
import { atom, useAtom, useSetAtom } from 'jotai';
import { resetSelectedPairsForGenerationAtom } from './use-ddq-state';
import { useRegion } from '@/lib/use-region';

// prioritize requeued's
// give user a way to requeue
// start list from currently-selected position
// redo failures after all successes have completed

// as soon as a search finishes loading, save it to state

export type ResultType = {
  [key: string]: {
    processing: boolean;
    complete: boolean;
    failed: boolean;
    response: DDQPairWithMeta[] | undefined;
  };
};

const progressiveSearchAtom = atom<ResultType>({});

export const progressiveSearchResultAtom = atom(
  (get) => {
    return get(progressiveSearchAtom);
  },
  (get, set, id: string, value?: Partial<ResultType[keyof ResultType]>) => {
    set(progressiveSearchAtom, {
      ...get(progressiveSearchAtom),
      [id]: {
        processing: false,
        complete: false,
        failed: false,
        response: undefined,
        ...(value || {})
      }
    });
  }
);

export const searchBiasAtom = atom('');

interface ResolvablePromise extends Promise<void> {
  isResolved?: boolean;
}

const MAX_PARALLEL = 3;

const getNextForProcessingAtom = atom(
  null,
  (get, _set, ddqPairs: SimilarPair[]) => {
    const searchBias = get(searchBiasAtom);
    const results = get(progressiveSearchResultAtom);

    const startIndex = Math.max(
      ddqPairs.findIndex((v) => v[0].id === searchBias),
      0
    );

    // Slice from the current search bias until the next max parallel results so we're not loading the whole ddq all at once anymore.
    const anyLeft = ddqPairs.map(p => getProcessingState(results, p[0].id)).filter(r => r.processing === false && r.complete === false && r.failed === false).length > 0;

    let workToDo = ddqPairs.slice(startIndex, startIndex + 5);
    if (startIndex > 0) workToDo.push(...ddqPairs.slice(startIndex - 2, startIndex));

    workToDo = workToDo.filter((v) => {
      const result = getProcessingState(results, v[0].id);

      return (
        result.processing === false &&
        result.complete === false &&
        result.failed === false
      );
    });

    return [anyLeft, workToDo.shift()] as [boolean, SimilarPair | undefined];
  }
);

const isProcessingAtom = atom(false);

export const useProgressiveSearch = () => {
  const { tokens } = useAuthInfo();

  const [isProcessing, setProgressiveProcessing] = useAtom(isProcessingAtom);

  const [results, setResult] = useAtom(progressiveSearchResultAtom);

  const getNextForProcessing = useSetAtom(getNextForProcessingAtom);

  const resetSelectedPairsForGeneration = useSetAtom(
    resetSelectedPairsForGenerationAtom
  );

  const requestProcessingPair = (ddqPair: SimilarPair) => {
    setResult(ddqPair[0].id, { complete: false, failed: false });

    process({ ddqPairs: [ddqPair] });
  };

  const startProcessingPair = (ddqPairId: string) => {
    setResult(ddqPairId, { complete: false, failed: false, processing: true });
  };

  const succeedProcessingPair = (
    ddqPairId: string,
    response: DDQPairWithMeta[]
  ) => {
    setResult(ddqPairId, {
      processing: false,
      complete: true,
      response: response
    });
  };

  const failProcessingPair = (ddqPairId: string) => {
    setResult(ddqPairId, {
      processing: false,
      complete: true,
      failed: true,
      response: undefined
    });
  };

  const { baseApiUrl } = useRegion();

  const process = async ({ ddqPairs }: { ddqPairs: SimilarPair[] }) => {
    console.log('beginning processing for similar_pairs ' + ddqPairs.length);

    setProgressiveProcessing(true);

    const processPair = async (pair: SimilarPair) => {
      const pairId = pair[0].id;
      const ddqId = pair[0].ddq_id;
      startProcessingPair(pairId);

      try {
        const accessToken = await tokens.getAccessToken();

        if (!accessToken) {
          throw new Error('Could not get access token.');
        }

        const response = await fetch(
          `${baseApiUrl}/ddq/${ddqId}/search/${pairId}`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );
        const data = (await response.json()) as SimilarPair;

        succeedProcessingPair(pairId, data[1]);

        resetSelectedPairsForGeneration(
          pairId,
          data[1].filter((d) => d.is_relevant === 'high').map((d) => d.pair.id)
        );
      } catch (error) {
        console.error('Error processing pair:', pairId, error);
        failProcessingPair(pairId);
      }
    };

    const processPairsInParallel = async () => {
      const maxParallel = MAX_PARALLEL;
      let activePromises: ResolvablePromise[] = [];

      let [anyLeft, pairToProcess] = getNextForProcessing(ddqPairs);
      while (anyLeft) {
        // Polling until the next one is ready to process.
        if (anyLeft && pairToProcess === undefined) {
          await new Promise((resolve) => setTimeout(resolve, 1000));

          [anyLeft, pairToProcess] = getNextForProcessing(ddqPairs);
        }

        if (pairToProcess === undefined) continue;

        if (activePromises.length >= maxParallel) {
          await Promise.race(activePromises);
          activePromises = activePromises.filter((p) => !p.isResolved);
        }

        startProcessingPair(pairToProcess[0].id);

        const promise = processPair(pairToProcess) as ResolvablePromise;
        promise.isResolved = false;
        promise.then(() => {
          promise.isResolved = true;
        });
        activePromises.push(promise);

        [anyLeft, pairToProcess] = getNextForProcessing(ddqPairs);
      }

      await Promise.all(activePromises);
      setProgressiveProcessing(false);
    };

    await processPairsInParallel();
  };

  return {
    isProcessing,
    results,
    process,
    requestProcessingPair
  };
};

export const getProcessingState = (
  results: ResultType,
  id: string
): ResultType[keyof ResultType] => {
  return (
    results[id] || {
      processing: false,
      complete: false,
      failed: false,
      response: undefined
    }
  );
};

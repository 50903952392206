import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { type Plugin } from 'unified';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import { visit, SKIP } from 'unist-util-visit';

import './markdown.css';
import { useRegion } from '@/lib/use-region';


 // Start Generation Here
export type RecursiveNode<T> = T & {
  children: RecursiveNode<T>[];
};

// Handles single-row Markdown tables in remark AST
const singleRowTablePlugin: Plugin = () => {
  return (tree) => {
    visit(
      tree,
      'table',
      (
        tableNode: RecursiveNode<typeof tree>,
        index: number,
        parent: RecursiveNode<typeof tree>
      ) => {
        const rowNodes: RecursiveNode<typeof tree>[] = [];
        visit(tableNode, 'tableRow', (rowNode) => {
          rowNodes.push(rowNode);
        });

        // If there's only one row in a Markdown table (regardless of head/body)
        if (rowNodes.length !== 1) return;

        const singleRow = rowNodes[0];
        // Transform row's cells into paragraphs
        const paragraphs = singleRow.children.map((cell) => ({
          ...cell,
          type: 'paragraph'
        }));

        // Replace the entire table with those paragraphs (avoid referencing original nodes)
        parent.children.splice(
          index,
          1,
          ...paragraphs.filter((p) => p.children && p.children.length > 0)
        );

        return [SKIP, index];
      }
    );
  };
};

// Handles single-tr HTML tables in rehype AST (tr can be in thead or tbody)
const singleTrHtmlTablePlugin: Plugin = () => {
  return (tree) => {
    visit(
      tree,
      'element',
      (
        node: RecursiveNode<typeof tree & { tagName: string }>,
        index,
        parent: RecursiveNode<typeof tree & { tagName: string }>
      ) => {
        if (node.children.length === 0 || node.tagName !== 'table') return;

        if (!node || !parent || typeof index === 'undefined') {
          return;
        }

        // Gather all <tr> elements within <table>,
        // even if nested within <thead> or <tbody>
        const trNodes: RecursiveNode<typeof tree & { tagName: string }>[] = [];
        const findTr = (
          n: RecursiveNode<typeof tree & { tagName: string }>
        ) => {
          if (n.type === 'element' && n.tagName === 'tr') {
            trNodes.push(n);
            return;
          }
          if (n.children && Array.isArray(n.children)) {
            n.children.forEach((child) => findTr(child));
          }
        };

        node.children.forEach((c) => findTr(c));

        if (trNodes.length !== 1) {
          return;
        }

        // If there's exactly one row in the entire table
        const singleRow = trNodes[0];
        // Transform row's cells into paragraphs
        const cellContents: RecursiveNode<typeof tree & { tagName: string }>[] =
          [];
        singleRow.children.forEach((child) => {
          if (
            child.type === 'element' &&
            (child.tagName === 'td' || child.tagName === 'th') &&
            child.children.length > 0
          ) {
            child.tagName = 'div';
            cellContents.push(child);
          }
        });
        // Replace the entire <table> with the paragraphs
        parent.children.splice(index, 1, ...cellContents);

        return [SKIP, index];
      }
    );
  };
};

const createUrlTransform = (baseApiUrl: string, ddqId: string) => {
  return (url: string) => {
    const mediaPattern = /^media\/(.+\..+)$/;
    const match = url.match(mediaPattern);
    if (match) {
      return `${baseApiUrl}/ddq/${ddqId}/media/${match[1]}`;
    }
    return url;
  };
};

const BaseMarkdown: React.ComponentType<{
  className?: string;
  content: string;
  ddqId?: string;
  disableProse?: boolean;
  enableImageFiltering?: boolean;
}> = ({
  className,
  content,
  ddqId,
  disableProse = false,
  enableImageFiltering = false
}) => {
  const { baseApiUrl } = useRegion();

  if (typeof content !== 'string') return <></>;

  let sanitizedContent = content;

  // Catch the case where a markdown code block is created.
  if (content.startsWith('```markdown') && content.endsWith('```')) {
    sanitizedContent = sanitizedContent.slice(0, sanitizedContent.length - 3);
    sanitizedContent = sanitizedContent.slice(11);
  }

  let urlTransform = undefined;
  if (ddqId) {
    urlTransform = createUrlTransform(baseApiUrl, ddqId);
  }

  const components = enableImageFiltering ? { img: () => null } : {};

  return (
    <ReactMarkdown
      className={`${disableProse ? '' : 'prose'} gg-markdown ${className}`}
      remarkPlugins={[
        [remarkGfm, { singleTilde: false }],
        // eslint-disable-next-line
        singleRowTablePlugin as any
      ]}
      rehypePlugins={[
        rehypeRaw,
        [
          rehypeSanitize,
          {
            ...defaultSchema,
            attributes: {
              ...defaultSchema.attributes,
              span: ['className']
            },
            tagNames: [
              ...(defaultSchema.tagNames || [])
                .filter(tag => tag !== 'blockquote' && tag !== 'code' && tag !== 'pre'),
              'mark'
            ]
          }
        ],
        // eslint-disable-next-line
        singleTrHtmlTablePlugin as any
      ]}
      components={components}
      urlTransform={urlTransform}
    >
      {sanitizedContent}
    </ReactMarkdown>
  );
};

export const Markdown = React.memo(BaseMarkdown);
